<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="mb-1">
          <b-card-title>Active Subscriptions - {{subscriptionListing}}</b-card-title>
        </b-card-title>
      </div>
    </b-card-header>

    <b-card-body>
      <!-- <vue-apex-charts
        type="line"
        height="400"
        :options="apexChatData.lineChartSimple.chartOptions"
        :series="apexChatData.lineChartSimple.series"
      /> -->
       <vue-apex-charts
        type="line"
        height="400"
        :options="getOption"
        :series="getData"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BBadge,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'
import { mapGetters } from "vuex";
import { $themeColors } from '@themeConfig'
export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  data() {
    return {
      apexChatData,
      
    }
  },
   computed: {
    ...mapGetters({ 
      getAllPropertyArray:"property/getAllProperty",
      lastSixMonthSubscriptionDataArray:"property/lastSixMonthSubscriptionData"
    }),
    getData(){
      var self = this;
       let series = [
          {
            data: self.lastSixMonthSubscriptionDataArray.data,
          },
        ]
      
      return series;
    },
    getOption(){
      var self = this;
       let chartOptions = {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        markers: {
          strokeWidth: 7,
          strokeOpacity: 1,
          strokeColors: [$themeColors.light],
          colors: [$themeColors.warning],
        },
        colors: [$themeColors.warning],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        tooltip: {
          custom(data) {
            return `${'<div class="px-1 py-50"><span>'}${
              data.series[data.seriesIndex][data.dataPointIndex]
            }</span></div>`
          },
        },
        xaxis: {
          categories: self.lastSixMonthSubscriptionDataArray.month
        },
        yaxis: {
          // opposite: isRtl,
        },
      }
        return chartOptions;
    },
    subscriptionListing(){
      var self = this;
      let subscriptionArray = [];
      if(self.getAllPropertyArray.length > 0){
        self.getAllPropertyArray.forEach(data => {
          if(data.subscriptionStatus == 1){
            subscriptionArray.push(data);
          }
        })
        if(subscriptionArray.length > 0){
          return subscriptionArray.length;
        }else{
          return "0";
        }
      }else{
        return "0";
      }
    }
   }
}
</script>
<style>
.apexcharts-xaxistooltip{
  display: none !important;
}
</style>
