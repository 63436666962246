<template>
  <div class="advertismentWrapper offerManagement dashBoardWrapper">
     <b-spinner style="width: 50px; height: 50px" class="spinner"  v-if="listingSpinner"/>
    <b-row class="ativeAllClassRow">
      <b-col md="4">
        <!-- <b-card> -->
          <!-- <card-statistic-profit-chart
            strokeColor="#edaebc"
            title="Active Listings"
            :value="activeListing"
            :graphData="lastSixMonthPropertyDataArray.data"
          /> -->
          <apex-line-chart-listing v-if="!listingSpinner" />
        <!-- </b-card> -->
      </b-col>
      <b-col md="4">
        <!-- <b-card> -->
          <!-- <card-statistic-profit-chart
            title="Active Subscriptions"
            :value="subscriptionListing"
            :graphData="lastSixMonthSubscriptionDataArray.data"
          /> -->
          <apex-line-chart-subscription v-if="!listingSpinner" />
        <!-- </b-card> -->
      </b-col>
      <b-col md="4">
        <!-- <b-card> -->
          <!-- <card-statistic-profit-chart
            strokeColor="#121231"
            title="Total Users"
            :value="activeUser"
            :graphData="lastSixmonthUserArray.data"
          /> -->
         <apex-line-chart  v-if="!listingSpinner" />
        <!-- </b-card> -->
      </b-col>
    </b-row>
     <b-row>
      <b-col md="12">
        <b-card>
          <b-row class="filterRow">
            <b-col md="9" sm="7" class="my-1">
              <label 
                style="
                  font-style: normal;
                  font-weight: 400;
                  font-size: 24px;
                  line-height: 29px;
                  color: #2c2c2c;
                "
                >Recent Subscriptions</label
              >
            </b-col>
            <b-col md="3" sm="5" class="my-1 myOfferSerachInput" v-if="subscriptionItems && subscriptionItems.length">
              <b-form-input placeholder="Search..." v-model="filter" />
            </b-col>
            <b-col cols="12" v-if="!listingSpinner">
              <b-table
                hover
                class="table_subscription"
                v-if="listingItems && listingItems.length"
                responsive
                :per-page="perPage"
                :current-page="subcurrentPage"
                :items="subscriptionItems"
                :fields="subscriptionFields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onSubFiltered"
              >
                <template #cell(propertyId)="data">
                  <div style="display: flex; align-items: center">
                    <a
                      :to="`property/${data.item.propertyId}`"
                      style="text-decoration: underline;"
                      @click="goToListingDetailPage(data.item)"
                    >
                      {{ data.item.propertyId }}
                    </a>
                  </div>
                </template>

                <template #cell(property)="data">
                  <div  @click="callPropertyEmit(data.item)">
                    <router-link target="_blank" :to="`listing-management/${data.item.id}`">
                    <div style="display: flex; align-items: center">
                      <img
                        v-if="data.item.photos && data.item.photos.length"
                        width="40px"
                        height="40px"
                        style="
                          object-fit: cover;
                          border-radius: 5px;
                          margin-right: 15px;
                          min-width: 40px;
                          cursor:pointer;
                        "
                        :src="data.item.photos[0]"
                        alt="property"
                      />
                      <div>
                        <span
                          style="
                            white-space: normal;
                            display: block;
                            color: #17D1C6;
                            font-weight: 500;
                            cursor:pointer;
                          "
                          >{{ data.item.PropertyLocation }}</span
                        >
                        <!-- <span class="askingPriceText">$ {{ data.item.askingPrice }}</span> -->
                      </div>
                    </div>
                    </router-link>
                  </div>
                </template>

                <template #cell(subscription_type)="data">
                  <div style="white-space: nowrap">
                    {{
                        data.item.listingType === 'half_way_or_shared'
                                ? 'Half-Way Or Shared House'
                                : data.item.listingType === 'mental_or_detox_facility'
                                ? 'Mental or Detox Facility'
                                : data.item.listingType === 'free_or_public_shelter'
                                ? 'Free or Public Shelter'
                                : ''
                    }}
                  </div>
                </template>
                  <template #cell(subscription_amount)="data">
                  <div style="white-space: nowrap">
                   {{data.item.subscription_amount}}
                  </div>
                </template>

                <template #cell(listed_by)="data">
                  <div
                    style="white-space: nowrap"
                    v-if="allUserData.filter((x) => x.id === data.item.userId).length"
                  >
                    {{
                      allUserData.filter((x) => x.id === data.item.userId)[0]
                        .firstName || ""
                    }}
                    {{
                      allUserData.filter((x) => x.id === data.item.userId)[0]
                        .lastName || ""
                    }}
                  </div>
                  <div style="white-space: nowrap" v-else>N/A</div>
                </template>

                <template #cell(status)="data">
                 <div :class="propertyStatus[data.item.status - 2].displayName" v-if="data.item.isArchived == false">
                    <p>
                      <span class="status-area">
                      {{
                        filteredStatuses.filter(
                          (x) => x.key === data.item.status
                        )[0].label
                      }}
                    </span>
                    </p>
                  </div>
                  <div v-else>
                    Archived
                  </div>
                </template>

                <template #cell(action)="data">
                 <div  :class="data.item.action" style="position:relative;">
                   <b-dropdown
                      :text="data.item.action"
                      :class="data.item.action"
                      :disabled="listingSpinner || data.item.isArchived == true"
                    >
                      <b-dropdown-item
                        style="text-transform: capitalize !important"
                        v-for="datas in displayStatus(
                          getSubStatus,
                          data.item.action
                        )"
                        :key="datas.displayName"
                        @click="openModel(data.item,datas)"
                        class="boldactive"
                        
                      >
                        {{ datas.displayName }}
                      </b-dropdown-item>
                    </b-dropdown>
                    <span style="white-space: nowrap;" v-if="data.item.status !== 5" class="auto-renew-span">Auto-renews On {{data.item.AutoRenewDate}}</span>
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col
              md="12"
              sm="12"
              class="my-1"
              v-if="
                !listingSpinner && viewAllSubscriptionDisplay && subscriptionItems.length > 0
              "
            >
              <span
                style="
                  white-space: nowrap;
                  display: block;
                  color: #17D1C6;
                  font-weight: 500;
                  text-align: right;
                  text-decoration: underline;
                  cursor: pointer;
                "
                @click="goToSubListPage()"
                >View All</span
              >
            </b-col>
            
            <b-col
              v-if="subscriptionItems.length == 0 && !listingSpinner"
              cols="12"
              style="width: 100%; text-align: center"
            >
              <p>No records found</p>
            </b-col>
           <b-col class="d-flex justify-content-between flex-wrap pt-0 paginationCol"  v-if="!viewAllSubscriptionDisplay && subscriptionItems && subscriptionItems.length">
              <!-- page length -->
              <div
                class="d-flex align-items-center"
                style="white-space: nowrap"
              >
                <span class="showText">Showing 1 to</span>
                <b-form-select
                  id="perPageSelect"
                  v-model="subperPage"
                  size="sm"
                  inline
                  :options="subpageOptions"
                  style="margin: 0px 10px"
                />
                <span class="entiresText">of {{ subscriptionItems.length }} </span> <span class="entiresText">entries</span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  v-model="subcurrentPage"
                  :total-rows="subscriptionItems.length"
                  :per-page="subperPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card>
          <b-row>
            <b-col md="9" sm="7" class="my-1">
              <label
                style="
                  font-style: normal;
                  font-weight: 400;
                  font-size: 24px;
                  line-height: 29px;
                  color: #2c2c2c;
                "
                >Recent Listings</label
              >
            </b-col>
            <b-col md="3" sm="5" class="my-1 myOfferSerachInput" v-if="listingItems && listingItems.length">
              <b-form-input placeholder="Search..." v-model="filterListing" />
            </b-col>
            <b-col cols="12" v-if="!listingSpinner">
              <b-table
                hover
                v-if="listingItems && listingItems.length"
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="listingItems"
                :fields="listingFields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filterListing"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(propertyId)="data">
                  <div style="display: flex; align-items: center">
                    <img
                      v-if="!data.item.isFeatured"
                      src="@/assets/images/svg/star_empty.svg"
                      alt="empty_star"
                       style="cursor: pointer;"
                      @click="StarFillup(data.item, true)"
                    />
                    <img
                      v-else
                      src="@/assets/images/svg/star_filled.svg"
                      alt="filled_star"
                       style="cursor: pointer;"
                      @click="StarFillup(data.item, false)"
                    />
                    <a
                      style="text-decoration: underline; margin-left: 10px"
                      @click="goToListingDetailPage(data.item)"
                    >
                      {{ data.item.propertyId }}
                    </a>
                  </div>
                </template>

                <template #cell(property)="data">
                  <div @click="callPropertyEmit(data.item)">
                    <router-link target="_blank" :to="`listing-management/${data.item.id}`">
                     <div style="display: flex; align-items: center">
                      <img
                        v-if="data.item.photos && data.item.photos.length"
                        width="40px"
                        height="40px"
                        style="
                          object-fit: cover;
                          border-radius: 5px;
                          margin-right: 15px;
                          min-width: 40px;
                          cursor:pointer;
                        "
                        :src="data.item.photos[0]"
                        alt="property"
                      />
                      <div>
                        <span
                          style="
                            white-space: normal;
                            display: block;
                            color: #17D1C6;
                            font-weight: 500;
                            cursor:pointer;
                          "
                          >{{ data.item.PropertyLocation }}</span
                        >
                        <!-- <span class="askingPriceText">$ {{data.item.askingPrice }}</span> -->
                      </div>
                     </div>
                    </router-link>
                  </div>
                </template>

                <template #cell(property_type)="data">
                  <div style="white-space: nowrap">
                    {{
                      data.item.listingType === 'half_way_or_shared'
                                ? 'Half-Way Or Shared House'
                                : data.item.listingType === 'mental_or_detox_facility'
                                ? 'Mental or Detox Facility'
                                : data.item.listingType === 'free_or_public_shelter'
                                ? 'Free or Public Shelter'
                                : ''
                    }}
                  </div>
                </template>

                <template #cell(listed_by)="data">
                  <div
                    style="white-space: nowrap"
                    v-if="allUserData.filter((x) => x.id === data.item.userId).length"
                  >
                    {{
                      allUserData.filter((x) => x.id === data.item.userId)[0]
                        .firstName || ""
                    }}
                    {{
                      allUserData.filter((x) => x.id === data.item.userId)[0]
                        .lastName || ""
                    }}
                  </div>
                  <div style="white-space: nowrap" v-else>N/A</div>
                </template>

                <template #cell(status)="data">
                  <div v-if="data.item.isArchived == false" :class="filteredStatuses.filter(
                          (x) => x.key === data.item.status
                        )[0].label">
                    <p>
                      <!-- <span class="status-area">
                      {{
                        filteredStatuses.filter(
                          (x) => x.key === data.item.status
                        )[0].label
                      }}
                    </span> -->
                    <b-dropdown
                      :text="propertyStatus[data.item.status-2].displayName"
                      :class="propertyStatus[data.item.status - 2].displayName"
                    >
                    <b-dropdown-item v-for="(statusP) in propertyStatus.filter((e)=>{return e.displayName !== 'pending'})" style="text-transform: capitalize;" v-b-tooltip.hover="{ placement: 'left', title: getHoverText(statusP.key,statusP.displayName) }" :key="statusP.key" @click="openStatusModel(statusP.key, data.item)">{{statusP.displayName}}</b-dropdown-item>
                    </b-dropdown>
                    </p>
                    <!-- <b-dropdown
                      style="width: -webkit-fill-available; margin-bottom: 5px;"
                      id="dropdown-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :text="filteredStatuses.filter((x) => x.key === data.item.status)[0].label"
                      :variant="filteredStatuses.filter((x) => x.key === data.item.status)[0].variant || 'dark'"
                    >
                      <b-dropdown-item v-for="(data2, index) in filteredStatuses" :key="index" variant="data2.variant" @click.prevent="data.item.status = data2.key">{{data2.label}}</b-dropdown-item>
                    </b-dropdown> -->
                    <!-- <span style="white-space: nowrap;">Auto-renews On 10/20/2022</span> -->
                  </div>
                  <div v-else>
                    Archived
                  </div>
                </template>

                <template #cell(action)="data">
                  <div style="display: flex" class="actionImage">
                    <img
                      src="@/assets/images/svg/eye.svg"
                      alt="empty_star"
                      style="margin-right: 10px; cursor: pointer;"
                      @click="goToListingDetailPage(data.item)"
                    />
                    <img 
                      v-if="data.item.isArchived == false"
                      src="@/assets/images/svg/edit.svg" 
                      alt="edit user"
                      style="cursor:pointer;"
                      @click="goToPropertyEditPage(data.item)"
                    />
                    <!-- <img src="@/assets/images/svg/delete.svg" alt="empty_star"/> -->
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col
              md="12"
              sm="12"
              class="my-1"
              v-if="
                !listingSpinner && viewAllDisplay && listingItems.length > 0
              "
            >
              <span
                style="
                  white-space: nowrap;
                  display: block;
                  color: #17D1C6;
                  font-weight: 500;
                  text-align: right;
                  text-decoration: underline;
                  cursor: pointer;
                "
                @click="goToListPage()"
                >View All</span
              >
            </b-col>
          
            <b-col
              v-if="listingItems.length == 0 && !listingSpinner"
              cols="12"
              style="width: 100%; text-align: center"
            >
              <p>No records found</p>
            </b-col>
           <b-col
              class="d-flex justify-content-between flex-wrap pt-0 paginationCol"
              v-if="!viewAllDisplay && listingItems && listingItems.length"
            >
            <!-- page length -->
            <div
                class="d-flex align-items-center"
                style="white-space: nowrap"
              >
                <span class="showText">Showing 1 to</span>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  inline
                  :options="pageOptions"
                  style="margin: 0px 10px"
                />
                <span class="entiresText">of {{  listingItems.length }} </span> <span class="entiresText">entries</span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="listingItems.length"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
     <b-modal
      id="modal-changeStatus"
      ok-title="Yes"
      cancel-title="No"
      centered
      @ok="changeStatus"
      :no-close-on-backdrop="true"
      hide-header
    >
      <h3>Are you sure you want to update the status?</h3>
    </b-modal>
    <b-modal
      id="modal-property-status"
      ok-title="Yes"
      cancel-title="No"
      centered
      @ok="checkForModalOpen"
      @cancel="cancelStatus"
      :no-close-on-backdrop="true"
      hide-header
    >
      <h3>{{statusChangeModalText}}</h3>
    </b-modal>
    <b-modal
      id="modal-warning-status"
      ok-only
      centered
      hide-footer
    >
      <h3>You can't change property staus because there is no subscription for this property</h3>
    </b-modal>
    <b-modal
      id="modal-cancle-status"
      ok-title="Yes"
      cancel-title="No"
      centered
      @ok="changeStatusProperty"
      @cancel="cancelChangeStatus"
      :no-close-on-backdrop="true"
      hide-header
    >
      <h3>Changing the status to CANCELLED OR SOLD OR DRAFT will require company contact with the user, to request the paid subscription again. Are you sure you want to change this property status?</h3>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
} from "bootstrap-vue";
import CardStatisticProfitChart from "@/views/card/card-statistic/CardStatisticProfitChart.vue";
import Ripple from "vue-ripple-directive";
import { getCollectionData,getDataWithSpecificWhereCondition } from "@/utils/FirebaseQueries/getQueries";
import * as updateTypesense from "@/utils/TypeSenseQueries/updateQueries.js";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import { dbCollections } from "@/utils/firebaseCollections";
import { mapGetters,mapActions } from "vuex";
import { loopFunction,getCommaSeperatedNumber } from "@/utils/commonMethods";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ApexLineChart from './ApexLineChart.vue'
import ApexLineChartListing from './ApexLineChartListing.vue'
import ApexLineChartSubscription from './ApexLineChartSubscription.vue'
import moment from "moment";
import axios from "axios";
import firebase from "@/utils/firebaseInit.js"
const db = firebase.firestore()
export default {
  name: "dashboard",
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
    CardStatisticProfitChart,
    ToastificationContent,
    ApexLineChart,
    ApexLineChartListing,
    ApexLineChartSubscription
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      listingSpinner: true,
      subscriptionSpinner: true,

      perPage: 10,
      pageOptions: [10, 25, 50],
      subpageOptions:[10, 25, 50],
      subperPage: 10,
      totalRows: 1,
      currentPage: 1,
      subcurrentPage:1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterListing: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      subscriptionFields: [
        {
          key: "propertyId",
          label: "Property ID",
        },
        "property",
        "subscription_type",
        "listed_by",
        "listing_date",
        "subscription_amount",
        {
          key: "status",
          label: "Status",
        },
        {
          key: "action",
          label: "Subscription Status",
        },
      ],
      subscriptionItems: [],

      listingFields: [
        {
          key: "propertyId",
          label: "Property ID",
        },
        "property",
        "property_type",
        "listed_by",
        "listing_date",
        {
          key: "offersCount",
          label: "Application Count",
        },
        {
          key: "status",
          label: "Status",
        },
        "action",
      ],
      listingItems: [],
      users: [],
      propertyLimit: 5,
      subscriptionLimit:5,
      viewAllDisplay: true,
      viewAllSubscriptionDisplay:true,
      subscriptionItems:[],
       updatedStatus: {},
      subStatusObject : {},
      statusChangeModalText: '',
      selectedProperty: {},
      isCanclleStatus: false,
      isSecondModal: false,
      // option: {
      //   xAxis: [
      //     {
      //       type: 'category',
      //       data: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
      //     },
      //   ],
      //   yAxis: [
      //     {
      //       type: 'value',
      //       splitLine: { show: false },
      //     },
      //   ],
      //   grid: {
      //     left: '40px',
      //     right: '30px',
      //     bottom: '30px',
      //   },
      //   series: [
      //     {
      //       name: 'Users',
      //       type: 'bar',
      //       stack: 'advertising',
      //       data: this.lastSixmonthUserArray,
      //       barMaxWidth: '20%',
      //       barMinWidth: '10px',
      //     },
      //   ],
      // },
    };
  },

  created() {
    var self = this;
     self.listingSpinner = true;
    self.init();
    self.allPropertyData().then(() => {
      self.getLastSixMonthPropertyData(self.getAllPropertyArray).then(() => {
        console.log(self.lastSixMonthPropertyDataArray,'lastSixMonthPropertyDataArray',self.lastSixMonthSubscriptionDataArray);
      });
    });
    self.getSubscriptionStatus().then(() => {
      self.subscriptionInit();
    })
    self.getUserData().then(() => {
      self.getLastSixMonthUsersData(self.allUserData).then(() => {
         self.listingSpinner = false;
        console.log(self.lastSixmonthUserArray,'lastSixmonthUserArray');
      });
    });
  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.listingItems.length;
  },

  methods: {
    ...mapActions({ getSubscriptionStatus: "admin/getSubscriptionStatus", 
                    getUserData: "user/userData",
                    allPropertyData:"property/allPropertyData",
                    getLastSixMonthUsersData:"user/getLastSixMonthUsersData",
                    getLastSixMonthPropertyData:"property/getLastSixMonthPropertyData"
                  }),
    getHoverText(key,statusType) {
      if(key == 2) {
        return '“Active ($)” is the only status in which you are being charged so people can find your open bed.';
      } else if(key == 3) {
        return '“Draft” means you need to move the listing to active and check out if you want to show your shelter location online. The draft is retained for 7 days.'
      } else if (key == 4) {
        return '“Full” means you are at capacity and the shelter location is showing externally in the Full area of Shelter-Registry.com at no charge.'
      } else if(key == 5) {
        return '“Cancelled” means the shelter location is no longer viable.'
      } else {
        return statusType
      }
    },
    
    init() {
      var self = this;
      self.listingSpinner = true;
      try {
        getCollectionData(
          `${dbCollections.PROPERTY}`,
          self.propertyLimit,
          null,
          { field: "createdAt", value: -1 }
        )
          .then((querySnapshot) => {
            self.listingItems = [];
            // self.listingSpinner = false;
            querySnapshot.forEach((doc) => {
              if (doc.data().listingType === "free_or_public") {
                self.listingItems.push({
                  ...doc.data(),
                  country: doc.data().contractBCountry,
                  state: doc.data().contractBState,
                  askingPrice: getCommaSeperatedNumber(doc.data().contractDAskingPrice),
                  photos: doc.data().contractFUploadPhotos,
                  listing_date: doc.data().createdAt
                    ? moment(doc.data().createdAt.seconds * 1000).format(
                        "MM/DD/YYYY"
                      )
                    : "",
                  PropertyLocation: `${
                        doc.data().contractBStreetNumber
                          ? doc.data().contractBStreetNumber
                          : ""
                      } ${
                        doc.data().contractBStreetName
                          ? doc.data().contractBStreetName + ","
                          : ""
                      } ${
                        doc.data().contractBUnitorLot
                          ? doc.data().contractBUnitorLot + ", "
                          : ""
                      }${doc.data().contractBCity}, ${
                        doc.data().contractBState
                      }, ${doc.data().contractBCountry}, ${
                        doc.data().contractBZipCode
                      }`,
                });
              } else if (doc.data().listingType === "half_way_or_shared" || doc.data().listingType == "mental_or_detox_facility" || doc.data().listingType == "free_or_public_shelter") {
                if(doc.data().status !== 1) {
                  self.listingItems.push({
                    ...doc.data(),
                    country: doc.data().leaseBCountry,
                    state: doc.data().leaseBState,
                    askingPrice: getCommaSeperatedNumber(doc.data().leaseDAskingPrice),
                    photos: doc.data().leaseFUploadPhotos,
                    listing_date: doc.data().createdAt
                      ? moment(doc.data().createdAt.seconds * 1000).format(
                          "MM/DD/YYYY"
                        )
                      : "",
                    PropertyLocation: `${
                          doc.data().leaseAStreetNumber
                            ? doc.data().leaseAStreetNumber
                            : ""
                        } ${
                          doc.data().leaseAStreetName
                            ? doc.data().leaseAStreetName + ","
                            : ""
                        } ${
                          doc.data().leaseAUnitorLot
                            ? doc.data().leaseAUnitorLot + ","
                            : ""
                        } ${doc.data().leaseBCity}, ${
                          doc.data().leaseBState
                        }, ${doc.data().leaseBCountry}, ${
                          doc.data().leaseBZipCode
                        }`,
                        isArchived: doc.data().isArchived ? doc.data().isArchived : false
                  });
                }
              } else if (doc.data().listingType === "mental_or_detox") {
                self.listingItems.push({
                  ...doc.data(),
                  country: doc.data().ownerBCountry,
                  state: doc.data().ownerBState,
                  askingPrice: getCommaSeperatedNumber(doc.data().ownerDAskingPrice),
                  photos: doc.data().ownerFUploadPhotos,
                  listing_date: doc.data().createdAt
                    ? moment(doc.data().createdAt.seconds * 1000).format(
                        "MM/DD/YYYY"
                      )
                    : "",
                  PropertyLocation: `${
                        doc.data().ownerBStreetNumber
                          ? doc.data().ownerBStreetNumber
                          : ""
                      } ${
                        doc.data().ownerBStreetName
                          ? doc.data().ownerBStreetName + ","
                          : ""
                      } ${
                        doc.data().ownerBUnitorLot
                          ? doc.data().ownerBUnitorLot + ", "
                          : "" 
                      }${doc.data().ownerBCity}, ${
                        doc.data().ownerBState
                      }, ${doc.data().ownerBCountry}, ${
                        doc.data().ownerBZipCode
                      }`, 
                });
              }
            });

            // loopFunction(querySnapshot.docs, (doc) => {
            //   return new Promise((resolve, reject) => {
            //     try {
            //       if (
            //         !self.users.filter((user) => user.id == doc.data().userId)
            //           .length
            //       ) {
            //         getCollectionData(
            //           `${dbCollections.USERS}>${doc.data().userId}`
            //         )
            //           .then((userDoc) => {
            //             self.users.push(userDoc.data());
            //             resolve({ status: true, userData: userDoc.data() });
            //             // self.listingSpinner = false;
            //           })
            //           .catch((error) => {
            //             resolve({ status: false, error: error });
            //             self.listingSpinner = false;
            //           });
            //       } else {
            //         resolve({
            //           status: false,
            //           error: new Error("User already added"),
            //         });
            //         self.listingSpinner = false;
            //       }
            //     } catch (error) {
            //       resolve({ status: false, error: error });
            //       self.listingSpinner = false;
            //     }
            //   });
            // }).catch((error) => {
            //   self.listingSpinner = false;
            //   console.error("ERROR in loop function: ", error);
            // });
          })
          .catch((error) => {
            self.listingSpinner = false;
            console.error("ERROR in get property data: ", error);
          });
        self.onFiltered(self.listingItems);
      } catch (error) {
        self.listingSpinner = false;
        console.error("ERROR in get collection data: ", error);
      }
    },
    subscriptionInit() {
      var self = this;
      self.listingSpinner = true;
      // console.log('getSubStatus',self.getSubStatus);
      try {
        getDataWithSpecificWhereCondition(dbCollections.PROPERTY,'subscriptionStatus','in',[1,3],self.subscriptionLimit,(querySnapshot) => {
            self.subscriptionItems = [];
            self.listingSpinner = false;
            querySnapshot.forEach((data) => {
              if(data.subscriptionStatus){
                if (data.listingType === "free_or_public") {
                  self.subscriptionItems.push({
                    ...data,
                    country: data.contractBCountry,
                    state: data.contractBState,
                    askingPrice: getCommaSeperatedNumber(data.contractDAskingPrice),
                    photos: data.contractFUploadPhotos,
                    listing_date: data.createdAt
                      ? moment(data.createdAt.seconds * 1000).format(
                          "MM/DD/YYYY"
                        )
                      : "",
                    subscription_amount:data.subscriptionAmountIndoller !== undefined ? "$" + data.subscriptionAmountIndoller :'',
                    action:data.subscriptionStatus ? self.getSubStatus[data.subscriptionStatus-1].displayName : 'N/A',
                    SubKey:data.subscriptionStatus,
                    Subscription:data.subscription ? data.subscription : '',
                    AutoRenewDate:data.autoRenewalDate ? moment(new Date(data.autoRenewalDate.seconds*1000)).format('MM/DD/YYYY') : 'N/A',
                    PropertyLocation: `${
                        data.contractBStreetNumber
                          ? data.contractBStreetNumber
                          : ""
                      } ${
                        data.contractBStreetName
                          ? data.contractBStreetName + ","
                          : ""
                      } ${
                        data.contractBUnitorLot
                          ? data.contractBUnitorLot + ", "
                          : ""
                      }${data.contractBCity}, ${
                        data.contractBState
                      }, ${data.contractBCountry}, ${
                        data.contractBZipCode
                      }`,
                  });
                } else if (data.listingType === "half_way_or_shared" || data.listingType == "mental_or_detox_facility" || data.listingType == "free_or_public_shelter") {
                  self.subscriptionItems.push({
                    ...data,
                    country: data.leaseBCountry,
                    state: data.leaseBState,
                    askingPrice: getCommaSeperatedNumber(data.leaseDAskingPrice),
                    photos: data.leaseFUploadPhotos,
                    listing_date: data.createdAt
                      ? moment(data.createdAt.seconds * 1000).format(
                          "MM/DD/YYYY"
                        )
                      : "",
                      subscription_amount:data.subscriptionAmountIndoller !== undefined ? "$" + data.subscriptionAmountIndoller :'',
                      action:data.subscriptionStatus ? self.getSubStatus[data.subscriptionStatus-1].displayName : 'N/A',
                      SubKey:data.subscriptionStatus,
                      Subscription:data.subscription ? data.subscription : '',
                      AutoRenewDate:data.autoRenewalDate ? moment(new Date(data.autoRenewalDate.seconds*1000)).format('MM/DD/YYYY') : 'N/A',
                      PropertyLocation: `${
                        data.leaseAStreetNumber
                          ? data.leaseAStreetNumber
                          : ""
                      } ${
                        data.leaseAStreetName
                          ? data.leaseAStreetName + ","
                          : ""
                      } ${
                        data.leaseAUnitorLot
                          ? data.leaseAUnitorLot + ","
                          : ""
                      } ${data.leaseBCity}, ${
                        data.leaseBState
                      }, ${data.leaseBCountry}, ${
                        data.leaseBZipCode
                      }`,
                      isArchived: data.isArchived ? data.isArchived : false,
                  });
                } else if (data.listingType === "mental_or_detox") {
                  self.subscriptionItems.push({
                    ...data,
                    country: data.ownerBCountry,
                    state: data.ownerBState,
                    askingPrice: getCommaSeperatedNumber(data.ownerDAskingPrice),
                    photos: data.ownerFUploadPhotos,
                    listing_date: data.createdAt
                      ? moment(data.createdAt.seconds * 1000).format(
                          "MM/DD/YYYY"
                        )
                      : "",
                      subscription_amount:data.subscriptionAmountIndoller !== undefined ? "$" + data.subscriptionAmountIndoller :'',
                      action:data.subscriptionStatus ? self.getSubStatus[data.subscriptionStatus-1].displayName : 'N/A',
                      SubKey:data.subscriptionStatus,
                      Subscription:data.subscription ? data.subscription : '',
                      AutoRenewDate:data.autoRenewalDate ? moment(new Date(data.autoRenewalDate.seconds*1000)).format('MM/DD/YYYY') : 'N/A',
                       PropertyLocation: `${
                        data.ownerBStreetNumber
                          ? data.ownerBStreetNumber
                          : ""
                      } ${
                        data.ownerBStreetName
                          ? data.ownerBStreetName + ","
                          : ""
                      } ${
                        data.ownerBUnitorLot
                          ? data.ownerBUnitorLot + ", "
                          : "" 
                      }${data.ownerBCity}, ${
                        data.ownerBState
                      }, ${data.ownerBCountry}, ${
                        data.ownerBZipCode
                      }`, 
                  });
                }
              }
            });

            // loopFunction(querySnapshot.docs, (doc) => {
            //   return new Promise((resolve, reject) => {
            //     try {
            //       if (
            //         !self.users.filter((user) => user.id == doc.data().userId)
            //           .length
            //       ) {
            //         getCollectionData(
            //           `${dbCollections.USERS}>${doc.data().userId}`
            //         )
            //           .then((userDoc) => {
            //             self.users.push(userDoc.data());
            //             resolve({ status: true, userData: userDoc.data() });
            //             self.listingSpinner = false;
            //           })
            //           .catch((error) => {
            //             resolve({ status: false, error: error });
            //             self.listingSpinner = false;
            //           });
            //       } else {
            //         resolve({
            //           status: false,
            //           error: new Error("User already added"),
            //         });
            //         self.listingSpinner = false;
            //       }
            //     } catch (error) {
            //       resolve({ status: false, error: error });
            //       self.listingSpinner = false;
            //     }
            //   });
            // }).catch((error) => {
            //   self.listingSpinner = false;
            //   console.error("ERROR in loop function: ", error);
            // });
        })
         
         
        self.onSubFiltered(self.subscriptionItems);
      } catch (error) {
        self.listingSpinner = false;
        console.error("ERROR in get collection data: ", error);
      }
    },
    getAllPropertyData() {
      var self = this;
      (self.propertyLimit = 0), (self.viewAllDisplay = false);
      self.init();
    },
    getAllSubscrioptionData() {
      var self = this;
      (self.subscriptionLimit = 0), (self.viewAllSubscriptionDisplay = false);
      self.subscriptionInit();
    },
    goToSubListPage(){
      var self = this;
      self.$router.push({ name : 'subscription-management'});
    },
    goToListPage(){
      var self = this;
      self.$router.push({ name : 'listing-management'});
    },
    onFiltered(listingItems) {
      // console.log("listingItems", listingItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = listingItems.length;
      this.currentPage = 1;
    },
    onSubFiltered(listingItems) {
      // console.log("listingItems", listingItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = listingItems.length;
      this.subcurrentPage = 1;
    },
    StarFillup(data, value) {
      var self = this;
      //   self.listingSpinner = true;
      let index = self.listingItems.findIndex((item) => {
        return item.id == data.id
      }) 
      if(index != -1){
         self.listingItems[index].isFeatured = value;
      }
     
      if (value == true) {
        self.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Property added in featured list",
            variant: "success",
            icon: "",
          },
        });
      } else {
        self.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Property remove from featured list",
            variant: "success",
            icon: "",
          },
        });
      }
      let updateObject = {
        isFeatured: value,
        updatedAt: new Date(),
      };
      let typeSenceObject = {
        isFeatured: value,
        updatedAt: parseInt(new Date().getTime() / 1000),
      };
      updateFirebase.updateRootCollectionDataByDocId(
        dbCollections.PROPERTY,
        data.id,
        updateObject,
        (res) => {
          //   self.showSpinner = false;
          updateTypesense
            .updatePropertyCollection(data.id, typeSenceObject)
            .then(() => {
              console.log("UPDATED IN TYPESENSE");
            })
            .catch((error) => {
              console.error("ERROR IN UPDATED IN TYPESENSE", error);
            });
        }
      );
    },
    goToListingDetailPage(data) {
      var self = this;
      self.$root.$emit("listingDetailPage", `${data.PropertyLocation}`);
      self.$router.push({
        name: "listing-management-detail",
        params: { id: data.id, name: `${data.state},${data.country}` },
      });
    },
    callPropertyEmit(data){
      var self = this;
      self.$root.$emit("listingDetailPage", `${data.PropertyLocation}`);
    },
    goToPropertyEditPage(data){
      var self = this;
      self.$root.$emit("listingDetailPage", `${data.PropertyLocation}`);
      self.$router.push({
        name: "listing-management-edit",
        params: { id: data.id, name: `${data.state},${data.country}` },
        query:{name:'dashboard'}
      });
    },
    displayStatus(array,status){
      let subStatusArray = [];
      if(array && array.length){
        array.forEach(data => {
          if(data.displayName != status){
            subStatusArray.push(data);
          }
        })
        return subStatusArray;
      }else{
        return [];
      }
    },
    openModel(data,datas) {
      console.log(data,datas,'(data,datas)')
      this.updatedStatus = data;
      this.subStatusObject = datas;
      this.$bvModal.show(`modal-changeStatus`);
    },
    changeStatus(){
      // debugger; // eslint-disable-line
      var self = this;
      self.listingSpinner = true;
      let index = self.subscriptionItems.findIndex(items => {
        return items.id == self.updatedStatus.id
      })
      console.log(index,'index',self.updatedStatus);
      if(index != -1){
        console.log(self.subStatusObject.key,'self.subStatusObject.key ------ self.updatedStatus',self.updatedStatus);
        // self.subStatusObject.key = self.subStatusObject.key === 1 ? 2 : self.subStatusObject.key === 2 ? 4 : self.subStatusObject.key === 3 ? 5 : 3;
        console.log(self.subStatusObject.key,'self.subStatusObject.key --- AFTER');
        self.subscriptionItems[index].SubKey = self.subStatusObject.key;
        self.subscriptionItems[index].Action = self.subStatusObject.displayName;
        self.subscriptionItems[index].status = self.filteredStatuses.find((e)=>{return e.key == (self.subStatusObject.key == 1 ? 2 : self.subStatusObject.key == 2 ? 4 : self.subStatusObject.key == 3 ? 5 : 3)}).displayName;
        console.log(self.subscriptionItems[index],'self.subscriptionItems[index]');
        if(self.updatedStatus.Subscription && self.updatedStatus.Subscription != ''){
          axios.post(process.env.VUE_APP_APIURL+'/api/v1/delete-subcription',{subcriptionId :self.updatedStatus.Subscription,statusKey:self.subStatusObject.key })
          .then((res) => {
            if(res){
              let updateObject = {
                status:self.subStatusObject.key == 1 ? 2 : self.subStatusObject.key == 2 ? 4 : self.subStatusObject.key == 3 ? 5 : 3,
                subscriptionStatus:self.subStatusObject.key
              }
              updateFirebase.updateRootCollectionDataByDocId(dbCollections.PROPERTY,self.updatedStatus.id,updateObject,(res) => {
                if(res){
                  self.$toast.success('Subscription status updated successfully');
                  self.listingSpinner = false;
                }
              })
            }
          }).catch((error) => {
            console.log('ERROR in call subscription delete api',error);
            self.listingSpinner = false;
          })
        }else{
          let updateObject = {
            status:self.subStatusObject.key == 1 ? 2 : self.subStatusObject.key == 2 ? 4 : self.subStatusObject.key == 3 ? 5 : 3,
            subscriptionStatus: self.subStatusObject.key == 3 ? 4 : self.subStatusObject.key == 4 ? 2 : 3
          }
          updateFirebase.updateRootCollectionDataByDocId(dbCollections.PROPERTY,self.updatedStatus.id,updateObject,(res) => {
            if(res){
              self.$toast.success('Subscription status updated successfully');
              self.listingSpinner = false;
            }
          })
        }
      }

    },
    displayFullName(name){
      if(name == 'Mental Or Detox'){
        return 'Mental Or Detox';
      }else{
        return name;
      }
    },
    openStatusModel(key, item){
      try {
        this.selectedProperty = {
          key: key,
          item: item
        }
        console.log("this.selectedProperty", this.selectedProperty)
        if(this.selectedProperty.key !== this.selectedProperty.item.status) {
          // this.$bvModal.show(`modal-property-status`);
          this.statusChangeModalText = 'Are you sure you want to change this property status?';
          if(this.selectedProperty.item.status == 2 || this.selectedProperty.item.status == 6) {
              if(this.selectedProperty.key === 2 || this.selectedProperty.key == 6) {
                  // this.statusChangeModalText = `Are you sure you want to change this property staus?`;
                  this.$bvModal.show(`modal-property-status`);
              }else if(this.selectedProperty.key === 3 || this.selectedProperty.key == 4) {
                  this.isCanclleStatus = true;
                  this.isSecondModal = true;
                  // this.statusChangeModalText = 'Are you sure you want to make this property status sold? It will cancle the subscription of the property';
                  this.$bvModal.show(`modal-property-status`);
              } else if(this.selectedProperty.key == 5) {
                  this.isCanclleStatus = true;
                  this.isSecondModal = true;
                  // this.statusChangeModalText = 'Are you sure you want to make this property status canclled? It will cancle the subscription of the property';
                  this.$bvModal.show(`modal-property-status`);
              }
          } 
          // else if(this.selectedProperty.item.status == 3) {
          //     if(this.selectedProperty.key === 2 || this.selectedProperty.key ===  6) {
          //         if(this.selectedProperty.item.subscriptionStatus !== 1){
          //           //warning can't change status
          //           this.$bvModal.show('modal-warning-status');
          //         } else {
          //             // this.statusChangeModalText = `Are you sure you want to change this property staus?`;
          //             this.$bvModal.show(`modal-property-status`);
          //         }
          //     }else if(this.selectedProperty.key == 4 ) {
          //         this.isSecondModal = true;
          //         if(this.selectedProperty.item.subscriptionStatus === 1){
          //             this.isCanclleStatus = true;
          //             // this.statusChangeModalText = 'Are you sure you want to change this property staus to sold? It will cancle the subscription of the property';
          //             this.$bvModal.show(`modal-property-status`);
          //         } 
          //         // else {
          //         //     // this.statusChangeModalText = `Are you sure you want to change this property staus?`;
          //         //     this.$bvModal.show(`modal-property-status`);
          //         // }
          //     } else if(this.selectedProperty.key == 5) {
          //         this.isSecondModal = true;
          //         if(this.selectedProperty.item.subscriptionStatus === 1){
          //             this.isCanclleStatus = true;
          //             // this.statusChangeModalText = 'Are you sure you want to change this property staus to canclled? It will cancle the subscription of the property';
          //             this.$bvModal.show(`modal-property-status`);
          //         } else {
          //             // this.statusChangeModalText = `Are you sure you want to change this property staus?`;
          //             this.$bvModal.show(`modal-property-status`);
          //         }
          //     }
          // }  
          else if(this.selectedProperty.item.status == 5 || this.selectedProperty.item.status == 4 || this.selectedProperty.item.status == 3) {
                if(this.selectedProperty.key === 2 || this.selectedProperty.key === 6) {
                   //warning can't change status
                   this.$bvModal.show('modal-warning-status');
                } else if(this.selectedProperty.key == 3) {
                    // this.statusChangeModalText = `Are you sure you want to change this property staus?`;
                    this.$bvModal.show(`modal-property-status`);
                } else if(this.selectedProperty.key == 4) { 
                    this.isSecondModal = true;
                    this.$bvModal.show(`modal-property-status`);
                }  else if(this.selectedProperty.key == 5) {
                    this.isSecondModal = true;
                    this.$bvModal.show(`modal-property-status`);
                }
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    cancelStatus() {
      this.$bvModal.hide('modal-property-status');
      this.selectedProperty = {};
      this.statusChangeModalText = '';
      this.isCanclleStatus = false;
    },
    cancelChangeStatus(){
      this.isSecondModal = false;
      this.isCanclleStatus = false;
      this.selectedProperty = {};
      this.$bvModal.hide('modal-cancle-status');
    },
    checkForModalOpen() {
        if(this.isSecondModal) {
            this.$bvModal.show('modal-cancle-status');
        } else {
            this.changeStatusProperty();
        }
    },
    changeStatusProperty() {
      var self = this;
      self.listingSpinner = true;
      if(self.isCanclleStatus) {
          self.cancleSubscripiton();
      } else {
          self.directStatusChange();
      }
    },
    cancleSubscripiton() {
        try {
            axios.post(process.env.VUE_APP_APIURL + '/api/v1/delete-subcription', {
                subcriptionId : this.selectedProperty.item.subscription,
                statusKey: this.selectedProperty.key
            })
            .then((deleteRes) =>{
                if(deleteRes.data.status) {
                   let index = this.listingItems.findIndex((val) => {
                      return val.id == this.selectedProperty.id
                    })
                    if(index > -1) {
                      this.listingItems[index].subscriptionStatus = 3;
                    }
                  this.directStatusChange();
                } else {
                  console.error(deleteRes.data);
                  this.listingSpinner = false;
                }
            })
            .catch((error) => {
                console.error(error);
                this.listingSpinner = false;
            })
        } catch (error) {
            console.error(error);
            this.listingSpinner = false;
        }
    },
    directStatusChange() {
      try {
          let updateObj = {
              status: this.selectedProperty.key,
              subscriptionStatus: this.selectedProperty.key == 3 ? 4 : this.selectedProperty.key == 4 ? 2 : 3,
              updatedAt: new Date()
          }
          if(this.selectedProperty.key === 4 || this.selectedProperty.key === 5 || this.selectedProperty.key === 3) {
            updateObj.removeDocTime = new Date();
          }
          db.collection(dbCollections.PROPERTY)
            .doc(this.selectedProperty.item.id)
            .update(updateObj)
            .then(() => {
                updateTypesense.updatePropertyCollection(this.selectedProperty.item.id, {status: parseInt(this.selectedProperty.key)}).then(() => {
                    console.log("UPDATED IN TYPESENSE");
                })
                .catch((error) =>{
                    console.error(error);
                })
                console.log("this.listingItems", this.listingItems)
                let index = this.listingItems.findIndex((val) => {
                  return val.id == this.selectedProperty.item.id
                });
                console.log("index")
                if(index > -1) {
                  this.listingItems[index].status = this.selectedProperty.key
                }
                this.listingSpinner = false;
                this.selectedProperty = {};
                this.statusChangeModalText = '';
                this.isCanclleStatus = false;
                if(this.isSecondModal) {
                  this.isSecondModal = false;
                }
                this.$bvModal.hide('modal-property-status');
                this.$toast.success("Status updated successfully");
            })
      } catch (error) {
          console.error(error);
          this.listingSpinner = false;
      }
    }
  },

  computed: {
    ...mapGetters({ 
      getStatuses: "admin/statusGetter",
      getSubStatus: "admin/subScriptionStatus", 
      allUserData: "user/allUserData",
      getAllPropertyArray:"property/getAllProperty",
      lastSixmonthUserArray:"user/lastSixmonthUser",
      lastSixMonthPropertyDataArray:"property/lastSixMonthPropertyData",
      lastSixMonthSubscriptionDataArray:"property/lastSixMonthSubscriptionData"
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    filteredStatuses() {
      return this.getStatuses.map((x) => {
        return {
          label: x.displayName,
          key: x.key,
        };
      });
    },
    propertyStatus() {
      let status = [...this.getStatuses]
      let draftInd = status.findIndex((data) => {
        return data.displayName == "draft"
      })
      if (draftInd>-1) {
        status.splice(draftInd,1)
        return status
      } else {
        return status
      }
    },
    // activeUser(){
    //   var self = this;
    //   let userArray = [];
    //   if(self.allUserData.length > 0){
    //     self.allUserData.forEach(data => {
    //       if(data.userRole != 'admin'){
    //         userArray.push(data);
    //       }
    //     })
    //     if(userArray.length > 0){
    //       return userArray.length
    //     }else{
    //       return "0";
    //     }
    //   }else{
    //     return "0";
    //   }
    // },
    // activeListing(){
    //   var self = this;
    //   let listingArray = [];
    //   if(self.getAllPropertyArray.length > 0){
    //     self.getAllPropertyArray.forEach(data => {
    //       if(data.status == 2 || data.status == 6){
    //         listingArray.push(data);
    //       }
    //     })
    //     if(listingArray.length > 0){
    //         return listingArray.length;
    //     }else{
    //       return "0";
    //     }
    //   }else{
    //     return "0";
    //   }
    // },
    // subscriptionListing(){
    //   var self = this;
    //   let subscriptionArray = [];
    //   if(self.getAllPropertyArray.length > 0){
    //     self.getAllPropertyArray.forEach(data => {
    //       if(data.subscriptionStatus == 1){
    //         subscriptionArray.push(data);
    //       }
    //     })
    //     if(subscriptionArray.length > 0){
    //       return subscriptionArray.length;
    //     }else{
    //       return "0";
    //     }
    //   }else{
    //     return "0";
    //   }
    // }
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.spinner {
  z-index: 999;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
.btn {
  text-transform: capitalize !important;
}
</style>
